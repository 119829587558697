// libraries
import {
  doc,
  DocumentData,
  getDoc,
  DocumentSnapshot,
  getDocFromCache,
} from "firebase/firestore";
import {
  getOrgDocPath,
  getTargetDocPath,
  getTenantDocPath,
} from "wombat-global/src/constants";

// services
import { firestore } from "../firebase.service";

// todo: move this document to getClientDocument
export function getDivisionDocument(
  orgId: string,
  tenantId: string,
  divisionId: string,
): Promise<DocumentSnapshot<DocumentData>> {
  if (!tenantId) {
    return Promise.reject(Error("tenantId must be provided"));
  }
  if (!divisionId) {
    return Promise.reject(Error("siteId must be provided"));
  }

  return getDoc(
    doc(firestore, getTargetDocPath({ orgId, tenantId, divisionId })),
  );
}

export function getSiteDocument({ siteId }: { siteId: string }) {
  return getDoc(doc(firestore, getTargetDocPath({ siteId })));
}

function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return false;
  }
}

export function getTenantDocument(
  orgId: string,
  tenantId: string,
): Promise<DocumentSnapshot<DocumentData>> {
  if (!tenantId) {
    return Promise.reject(Error("tenantId must be provided"));
  }

  // HACK: There was an issue with printing forms where the data request to the server failed when in an iframe
  // THIS SHOULD BE REMOVED WHEN FIXED
  if (isInIframe()) {
    return getDocFromCache(doc(firestore, getTenantDocPath(orgId, tenantId)));
  }
  return new Promise((res, rej) => {
    let hasReturned = false;
    getDoc(doc(firestore, getTenantDocPath(orgId, tenantId)))
      .then((d) => {
        if (!hasReturned) {
          hasReturned = true;
          res(d);
        }
      })
      .catch(rej);
    setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log("FALLBACK TO GET DATA FROM CACHE", hasReturned);
      if (!hasReturned) {
        /**
         * @TODO REMOVE THIS FALLBACK IF THIS CHANGE FIXES OUR ISSUE
         * https://github.com/firebase/firebase-js-sdk/pull/8595
         */
        getDocFromCache(doc(firestore, getTenantDocPath(orgId, tenantId)))
          .then((d) => {
            // eslint-disable-next-line no-console
            console.log("FALLBACK TO GET DATA FROM CACHE", d);
            hasReturned = true;
            res(d);
          })
          .catch(rej);
      }
    }, 500);
  });
}

export function getOrgTenantDocument(
  orgId: string,
  tenantId?: string,
): Promise<DocumentSnapshot<DocumentData>> {
  if (!tenantId) {
    return getDoc(doc(firestore, getOrgDocPath(orgId)));
  }
  return getDoc(doc(firestore, getTenantDocPath(orgId, tenantId)));
}
