// utilities
// import { swApi } from "../../service-workers/firebase-pre-cache/firebase-pre-cache.api";
import { resolveQueueItem } from "../../service-workers/firebase-pre-cache/firebase-pre-cache.utilities";

import { QueueItem } from "../../service-workers/firebase-pre-cache/types";

export async function precachePath<T = unknown>(
  path: string,
  meta: QueueItem["meta"],
  options?: {
    mainThread?: false;
  },
): Promise<void>;
export async function precachePath<T = unknown>(
  path: string,
  meta: QueueItem["meta"],
  options: {
    mainThread: true;
  },
): Promise<T | undefined>;
export async function precachePath<T = unknown>(
  path: string,
  meta: QueueItem["meta"],
  options?: {
    mainThread?: boolean;
  },
): Promise<T | void | undefined> {
  const item: QueueItem = {
    batch: path,
    path,
    meta,
  };

  /**
   * Push precache items into service worker queue
   */
  if (options?.mainThread) {
    const d = (await resolveQueueItem(item)) as T | undefined;
    return d;
  } else {
    // console.log("pushed item [SW]", Date.now() - start);
    // swApi.push([item], path);
    await resolveQueueItem(item);
    return;
  }
}
