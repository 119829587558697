// services
import {
  getObjectPath,
  getTrainingInstancePath,
  getUserPath,
} from "wombat-global/src/constants";
import { getGlobalUserDocumentFromCache } from "../../services/firebase/getGlobalUser";

// hooks
import { precachePath } from "../offline-support/precachePath";

// types
import { UserEntity } from "wombat-global/src/typings";
import { getTargetFromAccessKey } from "wombat-global/src/utilities";

// const start = Date.now();

export async function precacheUsersOfflineDocuments(userId: string) {
  // console.log("getGlobalUser - start", Date.now() - start);
  const userEntity = await getGlobalUserDocumentFromCache(userId);
  // console.log("getGlobUser - end", Date.now() - start);

  // set the organizations the user has access to
  await Promise.all(
    Object.keys(userEntity.access || {}).map((key) => {
      const target = getTargetFromAccessKey(key);
      return precachePath<UserEntity>(
        getUserPath(target, userId),
        {},
        { mainThread: true },
      ).then((_user) => {
        Object.entries(_user?.objects || {}).forEach(([, objectRef]) => {
          // console.log('object', objectRef)
          precachePath(
            getObjectPath({
              target,
              objectId: objectRef.id,
            }),
            {
              // if updatedAt equals to createdAt then the object is new ( set check property check to createAt not updateAt )
              ...(objectRef.createdAt !== objectRef.updatedAt
                ? { updatedAt: objectRef.updatedAt }
                : { createdAt: objectRef.createdAt }),
              // if updatedAt is not set then user createdAt prop
              ...(!objectRef.updatedAt
                ? { createdAt: objectRef.createdAt }
                : {}),
            },
          );
        });
        Object.entries(_user?.trainings || {}).forEach(([, trainingRef]) => {
          // console.log('training', trainingRef)
          precachePath(
            getTrainingInstancePath({
              target,
              instanceId: trainingRef.id,
            }),
            {
              updatedAt: trainingRef.updatedAt,
              // if createdAt is not set then user createdAT prop
              ...(!trainingRef.updatedAt
                ? { createdAt: trainingRef.createdAt }
                : {}),
            },
          );
        });
      });
    }),
  );
}
