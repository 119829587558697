// Firebase Collection keys
export const CONFIG_COLLECTION = "configs";
export const OBJECTS_COLLECTION = "objects";
export const TRAINING_COLLECTION = "training";
export const USERS_COLLECTION = "users";
export const FORMS_COLLECTION = "forms";
export const COMMENTS_COLLECTION = "comments";
export const ORGANIZATIONS_COLLECTION = "organizations";
export const TENANTS_COLLECTION = "tenants";
export const SITE_COLLECTION = "sites";
export const DIVISIONS_COLLECTION = "divisions";
export const DOCUMENTS_COLLECTION = "documents";
export const DASHBOARDS_COLLECTION = "dashboards";
export const VERSIONS_SUB_COLLECTION = "versions";
export const AUDIT_COLLECTION = "audit";
export const EXPORT_COLLECTION = "exports";
export const IMPORT_COLLECTION = "imports";
export const NOTIFICATION_COLLECTION = "notifications";
export const SHARE_COLLECTION = "share";
export const JOB_COLLECTION = "jobs";
export const CONTRACTORS_COLLECTION = "contractors";
export const SCHEDULED_TASKS_COLLECTION = "scheduled-tasks";

export const DOCUMENTS_META_COLLECTION = "documents_meta";
export const DOCUMENTS_ATTACHMENT_COLLECTION = "attachments";
export const STORAGE_COMMENTS_GENERAL = "comments";
export const DOCUMENT_VERSION_DRAFT = "draft";
export const GLOBAL_USERS_COLLECTION = "users";
export const SCHEMA_NAME = "schemas";

export const INDEXED_COLLECTION = "references";

export const TARGET_META_COLLECTION = "target_meta";

// Firebase Document keys
export const ALL_FIELDS = "all_fields";
export const ALL_TRAINING = "all_training";
export const ALL_VIDEO_ITEMS = "all_video_items";
export const ALL_ROLES = "all_roles";
export const ALL_SECTIONS = "all_sections";
export const ALL_GROUPS = "all_field_groups";
export const ALL_OBJECTS = "all_objects";
export const ALL_DASHBOARDS = "all_dashboards";
export const ALL_SCHEDULES = "all_schedules";
export const PERMISSIONS_DOC = "permissions";
export const WOMBAT_BUCKET_PREFIX = "wbt";
export const ALL_FORM_FOLDERS = "all_form_folders";
export const ALL_CONTRACTORS = "all_contractors";
export const CURRENT_METADATA_DOCUMENT = "current_metadata";

// GCP storage folder names (keys)
export const IMPORT_STORAGE_FOLDER = "imports";
export const EXPORT_STORAGE_FOLDER = "exports";
export const TRAINING_STORAGE_FOLDER = "trainings";
export const FORMS_STORAGE_FOLDER = "forms";
export const TASK_STORAGE_FOLDER = "objects";
export const PUBLIC_STORAGE_FOLDER = "public";
