// libraries
import { useMatch } from "@tanstack/react-location";
import { useMemo } from "react";
import { featureIsEnabled } from "wombat-global/src/feature-flags.maps";

// constants && types
import config from "../config";
import { TanUseMatch } from "../router/types";
import { IPermission } from "wombat-global/src/permission.maps";
import {
  IClientTarget,
  ISiteTarget,
  isClientTarget,
  isSiteTarget,
} from "wombat-global/src/typings";
import { getTargetFromParams } from "../router/utilities";

export function useFeatureFlags(): [number, boolean];
export function useFeatureFlags(options: {
  target?: IClientTarget | ISiteTarget;
}): [number, boolean];

export function useFeatureFlags(options?: {
  flagFilter: IPermission[];
}): [boolean, boolean];

export function useFeatureFlags(options?: {
  target: IClientTarget | ISiteTarget;
  flagFilter: IPermission[];
}): [boolean, boolean];

export function useFeatureFlags(options?: {
  target?: IClientTarget | ISiteTarget;
  flagFilter?: IPermission[];
}): [number, boolean] | [boolean, boolean] {
  const {
    data: { orgData, tenantData, siteData, divisionData },
    params,
    isLoading,
  } = useMatch<TanUseMatch>();

  const flags = useMemo(() => {
    const { target, flagFilter } = {
      target: getTargetFromParams(params),
      flagFilter: [],
      ...(options || {}),
    };

    // This override should only allow true => false conversion. If global hardcoded flag is false then that toggle is disabled
    // BitFags logic is, (available flags & ( target flags | default on flags)

    // override global with org, and org with tenant (only override true with false). I guess thats filtering.
    const dFeatureFlag = divisionData ? config.availableFlags & (divisionData.featureFlag | config.defaultOnFlags) : config.defaultOnFlags; // prettier-ignore
    const tFeatureFlag = tenantData ? config.availableFlags & (tenantData.featureFlag | config.defaultOnFlags): config.defaultOnFlags; // prettier-ignore
    const oFeatureFlag = orgData ? config.availableFlags & (orgData.featureFlag | config.defaultOnFlags) : config.defaultOnFlags; // prettier-ignore
    const sFeatureFlag = siteData ? config.availableFlags & (siteData.featureFlag | config.defaultOnFlags): config.defaultOnFlags // prettier-ignore

    let _flags = config.defaultOnFlags;
    if (isClientTarget(target) && target.divisionId) {
      _flags = dFeatureFlag;
    } else if (isClientTarget(target) && target.tenantId) {
      _flags = tFeatureFlag;
    } else if (isClientTarget(target) && target.orgId) {
      _flags = oFeatureFlag;
    } else if (isSiteTarget(target)) {
      _flags = sFeatureFlag;
    }

    if (flagFilter.length) {
      return flagFilter.every((flag) => featureIsEnabled(_flags, flag.bitFlag));
    }

    return _flags;
  }, [divisionData, options, orgData, params, siteData, tenantData]);

  return [flags, isLoading];
}

export function useGlobalFeatureFlags(...flagFilter: IPermission[]): boolean {
  return flagFilter.every((flag) =>
    featureIsEnabled(config.defaultOnFlags, flag.bitFlag),
  );
}
