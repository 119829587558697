// types and constants
import { dateFormatField } from "../../constants/dateFormat";
import { EntityStatus, IFieldGroupBuilt, IFieldTypes } from "../../typings";
import { ADDRESS_FORM_FIELDS } from "./address-form";
import { CONTACT_INFORMATION_FORM_GROUP } from "./user-contact-info";

export const EDIT_CONTRACTOR_FORM: IFieldGroupBuilt = {
  name: "editContractor",
  label: "Edit Contractor",
  groups: [
    {
      name: "contractor",
      label: "Basic Information",
      isSection: true,
      fields: [
        {
          fieldType: IFieldTypes.textField,
          span: 1,
          name: "name",
          label: "Name",
          required: true,
        },
        {
          fieldType: IFieldTypes.select,
          defaultValue: EntityStatus.active,
          required: true,
          options: [
            {
              label: "Inactive",
              value: EntityStatus.inactive,
            },
            {
              label: "Active",
              value: EntityStatus.active,
            },
          ],
          span: 1,
          name: "status",
          label: "Status",
        },
        { ...dateFormatField },
        {
          name: "timeZone",
          span: 1,
          label: "Time Zone",
          fieldType: IFieldTypes.timezone,
        },
      ],
    },
    {
      ...ADDRESS_FORM_FIELDS,
      min: 1,
      max: 1,
      label: "Home Address",
      name: "contractor.address",
    },
    {
      ...CONTACT_INFORMATION_FORM_GROUP,
      name: "contractor.contactInformation",
      label: "Contact Information",
      min: 1,
      max: 1,
    },
  ],
};
