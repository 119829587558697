/* eslint-disable no-console */
// libraries
import { Fragment } from "react";
import {
  Outlet,
  ReactLocation,
  Route,
  Router,
  createBrowserHistory,
} from "@tanstack/react-location";

// contexts
import { OfflineSupportProvider } from "../contexts/offline-support/offline-support.context";

// components
import { Auth } from "./Auth";
import LoadingWrapper from "../components/LoadingWrapper";
import Error404Component from "../components/404ErrorComponent";

// utilities
import { semiRandomId } from "../utilities/ids";
const start = Date.now();

// loaders
import { auth } from "../services/firebase.init";
import routeRedirectElement from "./asyncRedirect";

// constants
import { preservedRoutes, routeMap, ROUTES } from "./utilities";

// types
import { PathConfig, defaultPageConfig } from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FileExports = { default?: any; config?: PathConfig; [key: string]: any };

const regularRoutes = Object.keys(routeMap).reduce<Route[]>((routes, key) => {
  const route = ROUTES[key];
  const path = routeMap[key];

  path.split("/").reduce((acc: Route[], fragment, idx, keys) => {
    if (fragment === "") {
      return acc;
    }

    const routeFragment = acc?.find((rte) => rte.path == fragment);

    if (keys.length - 1 === idx) {
      const newRoute: Route = {
        path: "/",
        children: [],
        element: async function routeElement(match) {
          const mod = (await route()) as FileExports;
          const config: PathConfig = mod.config || defaultPageConfig;
          const useAuth = config.private || config.permission !== undefined;
          const AuthWrapper = useAuth ? Auth : Fragment;

          if (useAuth) {
            await auth.authStateReady();
          }

          return (
            <AuthWrapper params={match.params} config={config}>
              {mod.default ? <mod.default /> : "Nothing exported."}
            </AuthWrapper>
          );
        },
        loader: async function routeLoader(...args) {
          try {
            const mod = (await route()) as FileExports;
            const config: PathConfig = mod.config || defaultPageConfig;
            const useAuth = config.private || config.permission !== undefined;
            const id = semiRandomId();
            console.log(id, "loader init", mod.loader, Date.now() - start);
            if (mod.loader && useAuth) {
              console.log(id, "auth check", Date.now() - start);
              await auth.authStateReady();
              console.log(id, "auth ready", Date.now() - start);
            }
            console.log(id, "loader start", Date.now() - start, args);
            const l = await mod.loader?.(...args);
            console.log(id, "loader end", Date.now() - start);
            return l;
          } catch (err) {
            console.error("loading err", err);
            return null;
          }
        },
      };

      if (routeFragment) {
        // if fragment exits, add loaders to fragment so all children inherit them not just the "/" leaf
        routeFragment.loader = newRoute.loader;
        routeFragment.children
          ? routeFragment.children.push({ ...newRoute, loader: undefined })
          : (routeFragment.children = [{ ...newRoute, loader: undefined }]);
      } else {
        acc.push({
          path: fragment,
          children: [newRoute],
        });
      }
      return acc;
    }

    if (routeFragment) {
      return routeFragment.children as Route[];
    } else {
      acc.push({
        path: fragment,
        children: [],
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return acc.find((rte) => rte.path == fragment)!.children as Route[];
    }
  }, routes);

  return routes;
}, []);

const App = preservedRoutes._app || Fragment;
const NotFound = preservedRoutes["404"] || Fragment;

const location = new ReactLocation({
  history: createBrowserHistory({ window: globalThis.window }),
});

const routes = [
  ...regularRoutes,
  { path: "/", element: routeRedirectElement(NotFound) },
  { path: "*", element: routeRedirectElement(NotFound) },
];

export const Routes = () => {
  return (
    <Router
      location={location}
      routes={routes}
      defaultPendingMs={500}
      defaultPendingMinMs={500}
      defaultPendingElement={<LoadingWrapper when={true} />}
      defaultErrorElement={<Error404Component />}
      useErrorBoundary={true}
    >
      <App>
        <OfflineSupportProvider>
          <Outlet />
        </OfflineSupportProvider>
      </App>
    </Router>
  );
};
