import {
  IClientTarget,
  ISiteTarget,
  isClientTarget,
  isSiteTarget,
} from "../../typings";
import { checkPathContains, parsePath } from "../../utilities";
import {
  ALL_FIELDS,
  ALL_GROUPS,
  ALL_OBJECTS,
  ALL_ROLES,
  ALL_TRAINING,
  ALL_VIDEO_ITEMS,
  COMMENTS_COLLECTION,
  CONFIG_COLLECTION,
  DIVISIONS_COLLECTION,
  DOCUMENTS_COLLECTION,
  IMPORT_COLLECTION,
  INDEXED_COLLECTION,
  OBJECTS_COLLECTION,
  ORGANIZATIONS_COLLECTION,
  PERMISSIONS_DOC,
  SCHEMA_NAME,
  SITE_COLLECTION,
  TENANTS_COLLECTION,
  TRAINING_COLLECTION,
  USERS_COLLECTION,
} from "./constants";

/**
 * Get org / tenant collection template paths
 */
export const strp = [SITE_COLLECTION, "{siteId}"].join("/");
export const siteTemplatePath = [
  strp,
  "{siteCollectionName}",
  "{siteCollectionSiteId}",
].join("/");

export const otrp = [ORGANIZATIONS_COLLECTION, "{orgId}"].join("/");

export const orgTemplatePath = [
  otrp,
  "{orgCollectionName}",
  "{orgCollectionOrgId}",
].join("/");

export const ttrp = [orgTemplatePath, TENANTS_COLLECTION, "{tenantId}"].join(
  "/",
);
export const tenantTemplatePath = [
  ttrp,
  "{tenantCollectionName}",
  "{tenantCollectionTenantId}",
].join("/");

const dtrp = [tenantTemplatePath, DIVISIONS_COLLECTION, "{divisionId}"].join(
  "/",
);

export const divisionTemplatePath = [
  dtrp,
  "{divisionCollectionName}",
  "{divisionCollectionDivisionId}",
].join("/");

export function getTargetFromPath(
  path: string,
): IClientTarget | ISiteTarget | undefined {
  if (checkPathContains(path, strp)) {
    const site = parsePath(path, strp);
    return { siteId: site.siteId } as ISiteTarget;
  }

  if (checkPathContains(path, dtrp)) {
    const params = parsePath(path, dtrp);
    return {
      divisionId: params.divisionId,
      tenantId: params.tenantId,
      orgId: params.orgId,
    } as IClientTarget;
  }

  if (checkPathContains(path, ttrp)) {
    const params = parsePath(path, ttrp);
    return { tenantId: params.tenantId, orgId: params.orgId } as IClientTarget;
  }

  if (checkPathContains(path, otrp)) {
    const params = parsePath(path, otrp);
    return { orgId: params.orgId } as IClientTarget;
  }

  return undefined;
}

// org
export function getOrgDocPath(orgId: string): string {
  return [ORGANIZATIONS_COLLECTION, orgId].join("/");
}
export function getOrgPath(orgId: string = "_err"): string {
  const orgCollectionName = "C_" + orgId;
  const path = [ORGANIZATIONS_COLLECTION, orgId, orgCollectionName, orgId].join(
    "/",
  );
  return path;
}

// tenant
export function getTenantDocPath(orgId: string, tenantId: string): string {
  return [getOrgPath(orgId), TENANTS_COLLECTION, tenantId].join("/");
}
export function getTenantPath(orgId: string, tenantId: string): string {
  const tenantCollectionName = "C_" + orgId + "_" + tenantId;
  const path = [
    getOrgPath(orgId),
    TENANTS_COLLECTION,
    tenantId,
    tenantCollectionName,
    tenantId,
  ].join("/");
  return path;
}

// division
export function getDivisionDocPath(
  orgId: string,
  tenantId: string,
  divisionId: string,
): string {
  return [
    getTenantPath(orgId, tenantId),
    DIVISIONS_COLLECTION,
    divisionId,
  ].join("/");
}
export function getDivisionPath(
  orgId: string,
  tenantId: string,
  divisionId: string,
): string {
  const divCollectionName = "C_" + orgId + "_" + tenantId + "_" + divisionId;
  return [
    getTenantPath(orgId, tenantId),
    DIVISIONS_COLLECTION,
    divisionId,
    divCollectionName,
    divisionId,
  ].join("/");
}

// site
export function getSiteDocPath(siteId: string): string {
  return [SITE_COLLECTION, siteId].join("/");
}

export function getSitePath(siteId: string): string {
  const siteCollectionName = "S_" + siteId;
  const path = [SITE_COLLECTION, siteId, siteCollectionName, siteId].join("/");
  return path;
}

export function getTargetDocPath(target: IClientTarget | ISiteTarget): string {
  if (isSiteTarget(target)) {
    return getSiteDocPath(target.siteId);
  } else {
    const { divisionId, tenantId, orgId } = target;
    if (divisionId && tenantId) {
      return getDivisionDocPath(orgId, tenantId, divisionId);
    }
    if (tenantId) {
      return getTenantDocPath(orgId, tenantId);
    }
    return getOrgDocPath(orgId);
  }
}

/**
 *  Org / Tenant collection paths
 */

export function targetPath(target: IClientTarget | ISiteTarget): string {
  if (isClientTarget(target)) {
    const { orgId, tenantId, divisionId } = target;
    if (divisionId && tenantId) {
      return getDivisionPath(orgId, tenantId, divisionId);
    }

    if (tenantId && !divisionId) {
      return getTenantPath(orgId, tenantId);
    }

    return getOrgPath(orgId);
  } else {
    return getSitePath(target.siteId);
  }
}

/**
 * Resource document path getters
 */
export function getDocumentPath({
  target,
  documentId,
  commentId,
}: {
  target: IClientTarget | ISiteTarget;
  documentId: string;
  commentId?: string;
}): string {
  if (commentId) {
    return [
      targetPath(target),
      DOCUMENTS_COLLECTION,
      documentId,
      COMMENTS_COLLECTION,
      commentId,
    ].join("/");
  }
  return [targetPath(target), DOCUMENTS_COLLECTION, documentId].join("/");
}

export function getRolesPath(target: IClientTarget | ISiteTarget): string {
  return [targetPath(target), INDEXED_COLLECTION, ALL_ROLES].join("/");
}

export function getObjectPath({
  target,
  objectId,
}: {
  target: IClientTarget | ISiteTarget;
  objectId: string;
}): string {
  return [targetPath(target), OBJECTS_COLLECTION, objectId].join("/");
}

export function getPermissionDocPath(
  target: IClientTarget | ISiteTarget,
): string {
  return [targetPath(target), CONFIG_COLLECTION, PERMISSIONS_DOC].join("/");
}

export function getSchemaPath({
  target,
  schemaId,
}: {
  target: IClientTarget | ISiteTarget;

  schemaId: string;
}): string {
  return [targetPath(target), SCHEMA_NAME, schemaId].join("/");
}

export function getTrainingPath(target: IClientTarget | ISiteTarget): string {
  return [targetPath(target), TRAINING_COLLECTION].join("/");
}

export function getAllTrainingDocPaths(
  target: IClientTarget | ISiteTarget,
): string {
  return [targetPath(target), INDEXED_COLLECTION, ALL_TRAINING].join("/");
}

export function getAllRolesDocPaths(
  target: IClientTarget | ISiteTarget,
): string {
  return [targetPath(target), INDEXED_COLLECTION, ALL_ROLES].join("/");
}

export function getAllFieldsDocPaths(
  target: IClientTarget | ISiteTarget,
): string {
  return [targetPath(target), INDEXED_COLLECTION, ALL_FIELDS].join("/");
}

export function getAllFieldGroupsDocPaths(
  target: IClientTarget | ISiteTarget,
): string {
  return [targetPath(target), INDEXED_COLLECTION, ALL_GROUPS].join("/");
}

export function getAllObjectsDocPaths(
  target: IClientTarget | ISiteTarget,
): string {
  return [targetPath(target), INDEXED_COLLECTION, ALL_OBJECTS].join("/");
}

export function getAllVideoItemPaths(
  target: IClientTarget | ISiteTarget,
): string {
  return [targetPath(target), INDEXED_COLLECTION, ALL_VIDEO_ITEMS].join("/");
}

export function getUserCollectionPath(
  target: IClientTarget | ISiteTarget,
): string {
  return [targetPath(target), USERS_COLLECTION].join("/");
}

export function getUserPath(
  target: IClientTarget | ISiteTarget,
  userId: string,
): string {
  return [getUserCollectionPath(target), userId].join("/");
}

export function getUserPermissionsDoc({
  target,
  userId,
}: {
  target: IClientTarget | ISiteTarget;
  userId: string;
}): string {
  return [getUserPath(target, userId), CONFIG_COLLECTION, PERMISSIONS_DOC].join(
    "/",
  );
}

export function getImportDocumentPath(
  target: IClientTarget | ISiteTarget,
  importId: string,
): string {
  return [targetPath(target), IMPORT_COLLECTION, importId].join("/");
}

export function getTrainingInstancePath({
  target,
  instanceId,
}: {
  target: IClientTarget | ISiteTarget;
  instanceId: string;
}): string {
  return [targetPath(target), TRAINING_COLLECTION, instanceId].join("/");
}

export function getUserDocumentsPath({
  target,
  userId,
}: {
  target: IClientTarget | ISiteTarget;
  userId: string;
}): string {
  return [getUserCollectionPath(target), userId, DOCUMENTS_COLLECTION].join(
    "/",
  );
}
